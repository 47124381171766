import hosting from '../images/slicing/service/webhosting.png'
import webdesign from '../images/slicing/service/webdesign.png'
import seo from '../images/slicing/service/seo.png'
import uiux from '../images/slicing/service/ui.png'
import socialMedia from '../images/slicing/service/socialmedia.png'
import coreValues from '../images/slicing/service/1.png'
import seoAboutUs from '../images/slicing/our story/time.png'
import quick from '../images/slicing/our story/quick.png'
import uiAboutUs from '../images/slicing/home/ui.png'
import productEngineering from '../images/slicing/home/productEngineering.png'
import cloudNative from '../images/slicing/home/cloudNative.png'
import testEngineering from '../images/slicing/home/testEngineering.png'
import legacyModernization from '../images/slicing/home/legacyModernization.png'
export const servicesCardData = [
    [{
        heading: 'Product Engineering',
        imageSrc: productEngineering,
        key: '/productdevelopment/',
        hoverText: `World’s leading organizations are transforming businesses by adopting cloud, microservices architecture, API-led connectivity, JavaScript front-end frameworks and DevOps. This has increased both the importance and complexity of managing product portfolios and platform engineering.
        With 10+ years of product and platform engineering experience, we bring together the technology expertise, flexible operating models and people required to conceptualize, design, architect, develop and manage the lifecycle end-to-end. We deliver on our promise of efficiency, speed to market, innovation and differentiation in competitive markets.`,
        description: ' Product & Platform engineering services to conceptualize, architect, design, develop and manage your product lifecycle end-to-end, unlocking new levels of efficiency, innovation and growth.'
    },
    {
        heading: 'Legacy Modernization',
        imageSrc: legacyModernization,
        key: '/legacymodernization/',
        hoverText: `Enterprises have to continuously optimize, modernize IT systems, infrastructure and applications to keep up with customer, employee and stakeholder expectations. Businesses are held back on the roadway to modernization by complex challenges presented by expectations to support current business, create minimum disruption and optimize cost.
        To achieve this kind of efficiency, resiliency and agility, enterprises need a well thought out and comprehensive legacy modernization strategy to leverage cost saving advancements that also streamline operations, speed application development and reduce maintenance. We can get you there.`,
        description: 'We help you build a solid foundation for digital transformation. Our team work with you to find the right solutions your company needs to achieve business automation, intelligence and ensure business continuity from anywhere, anytime leveraging Cloud.'
    }],
    [{
        heading: 'Test Engineering',
        imageSrc: testEngineering,
        key: '/testingservice/',
        hoverText: `Software engineering teams and business today are under constant pressure to deliver an application which works every time, anywhere and anytime but is also delivered within right budget, time and high quality. In managing all of these parameters team at times drop the ball on the most important thing which is “Quality” of their code which can have a long-lasting impact on their goals.
        We have been working with customers to define a testing strategy which works on core principles of Technology, Domain, People and Processes. Our team collaborates with engineering and business to define what is the right technology layer to test, what are the scenarios required to be tested and what should be automated vs left to exploratory testing. This has helped us to deliver on the promise of high quality for every customer.`,
        description: ' Deliver with confidence, showcase value to business and accelerate the time to market with our testing services.'
    },
    {
        heading: 'Cloud Native',
        imageSrc: cloudNative,
        key: '/cloudnative/',
        sublist: ["Front-end development using frameworks such as Angular or React.",
            "Back-end development using Node.js and a focus on Serverless.",
            "Microservices framework focused on scalable JVM based systems using Spring and other reactive frameworks.",
            "Cloud native architectecture for creating the most mature end to end solutions using cloud native platforms such as Kubernetes."
        ],
        hoverText: `In modernization discussions, “cloud” now calls for contemporary software architectures,
containerized deployment, on-demand services and automation – and
is arguably the most important component of your digital journey.
Our full-range of software engineering services will help you on this journey.
`,
        description: ' Design and deliver future-forward cloud solutions, using DevOps, Microservices, Lean and Agile, Serverless, Containerization and Cloud Platforms'
    }]
]

export const testingStrategyServicesData = [
    [{
        heading: 'Test Automation',
        imageSrc: webdesign,
        description: 'Automation for us is not only about building scripts using Selenium, Appium, RestAssured. It’s about ensuring its reliable, usable and available. We focus on building scalable test automation frameworks which enable end to end user journey validations'
    },
    {
        heading: 'Performance Test Engineering',
        imageSrc: hosting,
        description: 'Application performance are important to business as 90% customers leave an application within 7 sec if they don’t see a response. GTEN performance engineering not only builds performance scenarios using tools like Jmeter, LoadRunner, WAPT but also works with engineering and DevOps teams to identify and fix the performance bottlenecks'
    },
    {
        heading: 'Mobile Application Testing',
        imageSrc: socialMedia,
        description: 'Functional testing of a mobile application is important but users can uninstall an application from their phone if it consumes battery, memory or has frequent crashes. Our mobile testing team while focusing on functional and performance of a mobile app, also validates battery, memory usage along with device heating using Android and XCode SDK.'
    }],
    [{
        heading: 'Cloud Infrastructure Testing',
        imageSrc: seo,
        description: 'Cloud usage has grown exponentially in last few years and every company wantsto use cloud for any future application development. Cloud engineers focus on setting up the infrastructure whereas our team focuses on testing if the setup is correct and is following best practices to reduce outages, cost and latency. Our cloud infrastructure automation suit has pre-built utilities to give you a quick analysis of your cloud servers.'
    },
    {
        heading: 'Automation Framework Consulting',
        imageSrc: uiux,
        description: 'Test automation projects succeed or fail not at scripting but at maintenance. A big factor to determine your test automation success is your framework design and architecture. GTEN technologies is known globally within our customer base for test automation framework architecture we have designed and built which have sustained technology revamp, functional transformation and tools change.'
    },
    {
        heading: 'Test Data Management',
        imageSrc: hosting,
        description: 'Every testing team has been at a bottleneck due to lack of availability of right test data at right time in format they need. Our team of test data engineers help you formulate a test data management strategy which helps you to create, synthesize, clean and make data availability across large distributed teams in multiple application complex environments.'
    }]
]


export const servicePageCardData = [
    [{
        heading: 'Web Design and Development',
        imageSrc: webdesign,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'Web Hosting and Security',
        imageSrc: hosting,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'Social media marketing',
        imageSrc: socialMedia,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    }],
    [{
        heading: 'Search Engine Optimization',
        imageSrc: seo,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'UI/UX and Graphic Desiging',
        imageSrc: uiux,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'Big data analysis',
        imageSrc: hosting,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    }]
]

export const progressBarData = [
    {
        heading: 'Search Engine Optimization',
        now: ' Quis ipsum gravida. Accumsan lacus vel facilisis dolore magna aliqua lacus veling.'
    },
    {
        heading: 'Email Marketing',
        now: ' Quis ipsum gravida. Accumsan lacus vel facilisis dolore magna aliqua lacus veling.'
    },
    {
        heading: 'Web Design and Development',
        now: ' Quis ipsum gravida. Accumsan lacus vel facilisis dolore magna aliqua lacus veling.'
    },
    {
        heading: 'Web Design and Development',
        now: ' Quis ipsum gravida. Accumsan lacus vel facilisis dolore magna aliqua lacus veling.'
    }
]

export const strategyDataServicePage = [
    {
        heading: 'VALIDATION',
        number: '01',
        description: 'Ensuring feasability of business idea and that all requisites are fulfilled at every step, between ideation to launch.'
    },
    {
        heading: 'CREATION',
        number: '02',
        description: 'A structured approach to launch MVP in fastest time, and developing strategies to capitalize on market trends through early adaptability.'
    },
    {
        heading: 'ANALYSIS',
        number: '03',
        description: 'Making use of consumer and market insights to create a well-defined roadmap, as well as evaluating future expansion scope and market forecasts.'
    },
    {
        heading: 'TRACTION',
        number: '04',
        description: 'Optimizing ROI, positioning the product to enlarge with target audience and enabling early adopters to quickly cross break-even point.'
    },
]
export const strategyOurStory = [
    {
        heading: '',
        number: '01',
        description: 'Equal Growth Opportunities for Everyone.'
    },
    {
        heading: '',
        number: '02',
        description: 'Honorable Work Practices.'
    },
    {
        heading: '',
        number: '03',
        description: 'Stimulating Work-environment. '
    },
    {
        heading: '',
        number: '04',
        description: 'Employee-centric Policies.'
    },
]
export const digitalProductEngineering = [
    {
        heading: 'Product Development DNA',
        readMore:'/productdevelopment/',
        number: '01',
        description: 'Decade old legacy of software product engineering and digital transformation.'
    },
    {
        heading: 'Partners in Innovation',
        number: '02',
        description: 'Excellent track record with start-ups and enterprise customers alike as a partner of choice for product engineering and bringing ideas to life.'
    },
    {
        heading: 'Flawless Delivery Execution Framework',
        number: '03',
        description: 'Combining the power of Design Thinking, Hackathons, Continuous Engineering and Agile tobuild next-generation digital products.'
    },
    {
        heading: 'Test Engineering',
        readMore:'/testingservice/',
        number: '04',
        description: 'We help to establish the right testing strategy to make right decision on where to test, when totest and how to test. Global product companies rely on our engineers for product quality.'
    },
]

export const testingStrategy = [
    {
        heading: 'DOMAIN',
        number: '01',
        description: 'We bring testers who have in-depth knowledge to not only understand the requirements but find gaps within them. Exploratory testing is a must have skill for everyone in our team.'
    },
    {
        heading: 'TECHNOLOGY',
        number: '02',
        description: 'We believe in establishing the right test pyramid which helps to test early and close to business logic. Our team is equally adept in API/Microservices testing and test automation as they are on User experience and engagement validation.'
    },
    {
        heading: 'TEAM',
        number: '03',
        description: 'Our testing teams for any project consists of 3 key skilled testers i.e., exploratorytesters, test automation engineers and SDET. SDET on our teams focus on optimization and efficiency improvements by building tools & utilities to help our testing teams.'
    },
    {
        heading: 'PROCESS',
        number: '04',
        description: 'A very efficient and skilled team can also falter if we don’t have the right processin place. We follow a KPI/Metrics driven process where outputs are measured against pre-defined goals and team adhere processes maintaining full traceability and accountability.'
    },
]

export const serviceCoreValues = [
    [{
        heading: 'Brilliant Client Service',
        imageSrc: coreValues
    },
    {
        heading: 'Flexibility & Adaptibility',
        imageSrc: coreValues
    }],
    [{
        heading: 'Adaptibility Differentiated',
        imageSrc: coreValues
    },
    {
        heading: 'People Professionalism',
        imageSrc: coreValues
    }],
    [{
        heading: 'Integrated Innovations',
        imageSrc: coreValues
    },
    {
        heading: 'An Insurgent Mindset',
        imageSrc: coreValues
    }]
]

export const servicesAboutUsPage = [
    [{
        heading: 'Great Design & Development',
        imageSrc: coreValues,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'Quick Response',
        imageSrc: quick,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    }, {
        heading: 'Time Saving',
        imageSrc: coreValues,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    }],
    [{
        heading: 'Best Support',
        imageSrc: seoAboutUs,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'Finest Quality',
        imageSrc: uiAboutUs,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    },
    {
        heading: 'Real Solutions',
        imageSrc: seoAboutUs,
        description: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad min im veniam, quis nostrud exercitati ullamco.'
    }]
]